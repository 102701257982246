import React from 'react';
import Modal from "react-modal";
import Image from 'next/future/image';
import Link from "next/link";
import moment from 'moment';
import TippyIcon from '../TippyIcon';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: 'calc(100% - 75px)',
        overflow: 'auto'
    },
};

const WorkshopDetails = ({
    isOpen,
    closeModal,
    workshopDetails,
    date,
    convertToHTML,
    eventUrl
}) => {
    const timeDiff = () => {
        const startTime = moment(workshopDetails.start_timestamp);
        const endTime = moment(workshopDetails.end_timestamp);
        const duration = moment.duration(endTime.diff(startTime));
        const hours = duration.asHours();
        const minutes = duration.asMinutes();

        if (minutes <= 60) {
            return `${minutes} Mins`
        }
        if (minutes > 60) {
            return `${hours} Hours`
        }
    }



    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => closeModal()}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
            >
                <div className="row p-3">
                    <div>
                        <i
                            className="fas fa-times closeIcon d-flex justify-content-end"
                            onClick={() => closeModal()}>
                        </i>
                    </div>
                    <div className="col-12 workshop-modal-title-section">
                        <div className="d-flex justify-content-between">
                            <span className="workshop-modal-title">
                                {workshopDetails.workshop_title}
                            </span>

                        </div>
                    </div>
                    <div className="col-12 workshop-modal-date-section">
                        <span className="workshop-modal-date">{date(workshopDetails)}</span>
                        <span className="workshop-modal-date date-diff">
                            <img className="time-img" src="/img/Time.svg" alt="time" />
                            {timeDiff(workshopDetails)}
                        </span>
                    </div>
                    {workshopDetails.is_virtual === true ? (
                        <div className='col-12 workshop-modal-location-section'>
                            {workshopDetails.url && (
                                <img className="online-img" src="/Event/icons/online.png" alt="location" />
                            )}
                            <div className="workshop-modal-location">
                            <a href={workshopDetails.url} target="_blank">
                               {workshopDetails.url}
                            </a> 
                            </div>
                        </div>
                        
                        ) : (
                        <div className='col-12 workshop-modal-location-section'>
                            {workshopDetails.venue && (
                                <img className="location-img" src="/img/location_two_icon.svg" alt="location" />
                            )}
                            <div className="workshop-modal-location">
                                {workshopDetails.venue}
                            </div>
                        </div>
                    )}
                    
                    <div className="col-12 workshop-modal-description-section">
                        <p className="workshop-modal-description" dangerouslySetInnerHTML={convertToHTML(workshopDetails)} />
                    </div>
                    <div className="workshop-modal-speaker-section">
                        {workshopDetails.workshop_speakers && workshopDetails.workshop_speakers.length > 1 ? <p className="speaker-title">Speakers:</p>
                            : workshopDetails.workshop_speakers.length === 1 ? <p className="speaker-title">Speaker:</p>
                                : ''
                        }
                        {workshopDetails.workshop_speakers && workshopDetails.workshop_speakers.map((speaker, index) => (
                            <TippyIcon
                                content={speaker.name}
                                placement='top'
                                key={index}
                            >
                                <img className="workshop-speaker-image position-relative rounded-full" src={speaker.image_url} alt="Speaker"
                                    width={60}
                                    height={60}
                                    style={{
                                        borderRadius: '50%',
                                        marginRight: '9px'
                                    }}
                                />
                            </TippyIcon>
                        ))}
                    </div>
                    {workshopDetails.workshop_tickets[0] && (
                        <Link
                            href={{
                                pathname: `/checkout/${eventUrl}`,
                                query: {
                                    ticketId: `${workshopDetails.workshop_tickets[0].ticket_id}`,
                                },
                            }}
                        >
                            <div className="col-12 register-btn rounded mt-4">
                                <span className="site-link-text text-white text-center text-decoration-none buy-btn d-flex align-items-center justify-content-around rounded py-2 px-2">Register</span>
                            </div>
                        </Link>)}
                </div>
            </Modal>
            <style jsx>{`
                .closeIcon {
                    font-size: 1.5rem;
                    color: black;
                    opacity: 0.5;
                    cursor: pointer;
                }
                .workshop-modal-title{
                    font-family: 'Nunito';
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    color: #161616;
                }

                .workshop-modal-date-section{
                    margin-top: 30px;
                }
                .workshop-modal-date{
                    font-family: 'Prompt';
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #FB5850;
                }
                .date-diff{
                    margin-left: 20px;
                    background:  #FFF1F0;
                    border-radius: 5px;
                    padding: 6px 13px;
                    line-height: 36px;
                }
                .time-img{
                    margin-right: 11px;
                    margin-top: -2px;
                }

                .workshop-modal-location-section{
                    margin-top: 27px;
                    display: flex;
                    // align-items: center;
                }
                .workshop-modal-location{
                    margin-left: 12px;
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #000000;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-all;
                    display: block;
                    max-width: 100%; /* Ensure the link doesn't exceed its container */
                }
                .workshop-modal-location a {
                    text-decoration: none;
                }

                .workshop-modal-description-section{
                    margin-top: 25px;
                }
                .workshop-modal-description{
                    font-family: 'Prompt';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                }
                .speaker-title{
                    margin-top: 15px;
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #FB5850;
                }
            `}</style>
        </>
    )
}

export default WorkshopDetails;
