
    import React, { Component } from "react";
    


   
   export default class GoogleMap extends Component {
      constructor(props) {
        super(props);
        this.state = {
          map: "",
          marker: "",
          infowindow:""
        };
      }
    
      onScriptLoad() {
        let map = new window.google.maps.Map( // initialise map
          document.getElementById(this.props.id),
          // this.props.MapOptions, 
          {...this.props.MapOptions, mapTypeId: google.maps.MapTypeId.ROADMAP}
        );
        let infowindow =  new window.google.maps.InfoWindow({ // initialise title on marker
          content: this.props.place,
          map: map,
          center: this.props.MapOptions.center,
        });
        let marker = new window.google.maps.Marker({ // initialise marker
            ...this.props.marker,
          map: map,
          url: `https://www.google.com/maps/search/?api=1&query=${this.props.place.replace(/ /g, "+")}`, // url to google maps
          animation: google.maps.Animation.DROP
        });

        infowindow.open(map, marker); //display the marker info title

        marker.addListener('mouseover', function() {
          infowindow.open(map, marker);
        });

        marker.addListener('click', function() {
          infowindow.open(map, marker);
          window.open(marker.url, "_blank")
      });
      }
    
      componentDidMount() {
        if (!window.google) {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.src = `https://maps.google.com/maps/api/js?key=${this.props.apiKey}`;
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
          s.addEventListener("load", e => {
            this.onScriptLoad();
          });
        } else {
          this.onScriptLoad();
        }
      }
    
   
    
      render() {
        return <>
                <div className="map" id={this.props.id} />
                <style>
                  {
                    `.map{
                        position:static !important;
                    }
                    // .gm-style-iw button {
                    //   display: none !important;
                    // }
                    `
                  }
                </style>
              </>
      }
    }