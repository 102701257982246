import React, { Fragment, useContext, useState } from 'react';
import Image from 'next/future/image';
import moment from 'moment-timezone';
import { convertTimezone } from '../helpers/common';
import eventContext from '../contexts/EventContext';
import WorkshopDetails from './Modal/WorkshopDetails';
import TippyIcon from './TippyIcon';

const WorkshopCard = ({ workshopDetails, eventUrl, color=null }) => {
    const [openModal, setOpenModal] = useState(false)
    const { eventData } = useContext(eventContext)
    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    const date = (workshopDetails) => {
        let start_month = getDate(`${workshopDetails.start_timestamp}`, 'MMM Do');
        // let end_month = getDate(`${workshopDetails.end_timestamp}`, 'MMM Do');
        let start_year = getDate(`${workshopDetails.start_timestamp}`, 'YYYY');
        // let end_year = getDate(`${workshopDetails.end_timestamp}`, 'YYYY');
        let start_time = getDate(`${workshopDetails.start_timestamp}`, "h:mm A");
        // let end_time = getDate(`${workshopDetails.end_timestamp}`, "h:mm A");
        let timezone = moment.tz(`${workshopDetails.end_timestamp}`, eventData.time_zone).format("z");

        return `${start_month}, ${start_year} at ${start_time} (${timezone})`
    }

    function convertToHTML(workshopDetails) {
        return { __html: `${workshopDetails?.workshop_description?.replace(/(?:\r\n|\r|\n)/g, '') || '' }`};
    }

    return (
        <Fragment>
            <div className="workshop-card">
                {workshopDetails.workshop_banner_url && <div
                >
                    <Image fill className="workhop-image position-relative" src={workshopDetails.workshop_banner_url} alt="Banner"
                        style={{
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            objectFit: "cover"
                        }} />
                </div>}
                <div className="row workshop-details-section">
                    <div className="col-12 workshop-title-section">
                        <span className="workshop-title">
                            {workshopDetails.workshop_title}
                        </span>
                    </div>

                    <div className="col-12 workshop-date-section">
                        <span className="workshop-date">{date(workshopDetails)}</span>
                    </div>
                    {
                        workshopDetails.workshop_description &&
                        <div className={`${workshopDetails.workshop_banner_url ? "workshop-description-section" : "workshop-description-section-noImage"} col-12`}>
                            <p className="workshop-description" dangerouslySetInnerHTML={convertToHTML(workshopDetails)} />
                        </div>
                    }
                    {
                        <div className="workshop-speaker-section">
                            {workshopDetails.workshop_speakers && workshopDetails.workshop_speakers.map((speaker, index) => (
                                <TippyIcon
                                    content={speaker.name}
                                    placement='top'
                                >
                                    <img className="workshp-speaker-image position-relative rounded-full" src={speaker.image_url} alt="Speaker"
                                        width={60}
                                        height={60}
                                        style={{
                                            borderRadius: '50%',
                                            marginRight: '9px'
                                        }}
                                    />
                                </TippyIcon>

                            ))}
                        </div>
                    }

                    <div className="col-12 view-btn rounded">
                        <span onClick={() => setOpenModal(true)} className="site-link-text text-white text-center text-decoration-none buy-btn d-flex align-items-center justify-content-around rounded py-2 px-2">
                            View Details
                        </span>
                    </div>
                </div>
                {openModal &&
                    <WorkshopDetails
                        workshopDetails={workshopDetails}
                        date={date}
                        convertToHTML={convertToHTML}
                        eventUrl={eventUrl}
                        isOpen={openModal}
                        closeModal={() => setOpenModal(false)}
                    />
                }
            </div>
            <style jsx>{`
                .workshop-card {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    padding: 4px 4px;
                    position: relative;
                }

                .workshop-details-section{
                    padding: 0 25px;
                }

                .workshop-title-section {
                    height: 70px;
                    margin-top: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-title{
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 35px;
                    color: #161616;
                }

                .workshop-date-section{
                    margin-top: 15px;
                }
                .workshop-date{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: ${color ? color: "#FB5850"};
                }

                .workshop-description-section{
                    height: 62px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-description-section-noImage{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 14; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-description{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: #101010;
                    margin-top: 15px;
                }

                .workshop-speaker-section{
                    display: flex;
                    margin-top: 20px;
                    height: 55px;
                }

                .view-btn{
                    margin: 28px 0;
                    // position: absolute;
                    // bottom: 20px;
                    // right: -17px;
                    display: flex;
                }
                .view-btn *{
                    font-family: Prompt;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                @media (max-width: 1067px) {
                    .workshop-title-section {
                        height: auto;
                    }

                    .workshop-description-section{
                        height: auto;
                    }

                    .workshop-speaker-section{
                        height: auto;s
                    }
                }

            `}</style>
        </Fragment>
    )
}

export default WorkshopCard
